









import Loading from '@/shared/components/Loading.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({ components: { Loading } })
export default class Map extends Vue {
  // constructor
  constructor() {
    super();
  }
}
